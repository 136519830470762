.root {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  height: 25px;
  padding-inline-end: 8px;
}

.no-icon {
  grid-template-columns: auto 1fr auto;
}

.expandIconWrapper {
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.expandIconWrapper svg {
  width: 16px;
  height: 16px;
  color: #cccccc;
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.labelGridItem {
  padding-inline-start: 5px;
}

.cursorPointer {
  cursor: pointer;
}

.text13 {
  color: #cccccc;
  font-size: 13px !important;
}

.justifyEnd {
  justify-self: end !important;
}
