.rootGrid {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.fileChooser {
  padding: 32px 32px 0;
}

.treeRoot {
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  list-style: none;
  margin: 0;
}

.file-list ul {
  list-style: none;
  padding-left: 15px;
}
/* .file-list > ul > li:first-child > div:first-child {
  background-color: #262525;
} */
.file-list > ul > li:first-child > div:first-child p {
  font-size: 11px !important;
  font-weight: 700;
  text-transform: uppercase;
}

.file-list > ul > li > ul {
  height: calc(100vh - 57px) !important;
  overflow-y: auto;
  padding-left: 15px;
}

.file-list > ul > li > ul::-webkit-scrollbar {
  width: 10px;
}

.file-list > ul > li > ul::-webkit-scrollbar-track {
  box-shadow: 0 none;
}

.file-list > ul > li > ul::-webkit-scrollbar-thumb {
  background-color: #403f3f;
  outline: 1px solid #403f3f;
}

.draggingSource {
  opacity: 0.3;
}

.dropTarget {
  background-color: #e8f0fe;
}
